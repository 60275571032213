import { GridCellParams, MuiEvent } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import TableEdit from "./TableEdit/TableEdit";
import TableNormal from "./TableNormal/TableNormal";

function DataGridEditabled(props: any) {
  const { rows, columns, title, onlyEditFunct } = props;
  const [edit, setEdit] = useState<boolean>(false);
  useEffect(() => {
    setEdit(onlyEditFunct);
  }, [onlyEditFunct]);

  return (
    <>
      {!edit ? (
        <TableNormal
          {...props}
          onCellClick={props.onCellClick}
          rows={rows}
          columns={columns}
          setEdit={setEdit}
          title={title}
        ></TableNormal>
      ) : (
        <TableEdit
          {...props}
          onCellClick={(
            params: GridCellParams,
            event: MuiEvent<React.MouseEvent>
          ) => {
          }}
          autoSave={props.autoSave}
          onlyEdit={props.onlyEdit}
          handleSave={props.handleSave}
          checkSave={props.checkSave}
          rows={rows}
          columns={columns}
          setEdit={setEdit}
          title={title}
          onlyEditFunct={onlyEditFunct}
        ></TableEdit>
      )}
    </>
  );
}

export default DataGridEditabled;
