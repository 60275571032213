import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
function PDFObjective(props: any) {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View
          style={{
            paddingTop: "7px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Image
            style={{ height: "55px", width: "55px" }}
            src="/Assets/img/LogoAedasSmall.png"
          ></Image>
          <View style={{ flexGrow: 1, paddingTop: "7px" }}>
            <Text
              style={{
                fontSize: "12px",
                fontFamily: "Heebo-bold",
                fontWeight: "black",
              }}
            >
              Objetivos anuales
            </Text>
            <Text
              style={{
                fontSize: "8px",
                color: "grey",
              }}
            >
              {props.year + "-" + (props.year + 1)}
            </Text>
            <Text
              style={{ fontSize: "8px", color: "grey", fontWeight: "bold" }}
            >
              Fecha descarga:{moment().format("DD/MM/YYYY HH:mm").toString()}{" "}
            </Text>
          </View>

          <View style={{ flexGrow: 0, paddingTop: "7px" }}>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Heebo-bold",
                fontWeight: "black",
              }}
            >
              Porcentaje del variable
            </Text>
            <Text
              style={{
                fontSize: "8px",
                color: "grey",
              }}
            >
              {props.percentage}
            </Text>
          </View>
        </View>
        <View style={styles.tableContainer}>
          <View style={styles.container}>
            <Text style={styles.description}>Objetivo</Text>
            <Text style={styles.value}>Valoración % s/retirb. fija</Text>
            {props.columns
              .filter(
                (column: any) =>
                  !column.hide &&
                  column.field !== "valoration" &&
                  column.field !== "objective"
              )
              .map((column: any) => (
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderColor: "white",
                    fontSize: "10px",
                    fontFamily: "Heebo-bold",
                    width:
                      40 /
                        props.columns.filter(
                          (column: any) =>
                            !column.hide &&
                            column.field !== "valoration" &&
                            column.field !== "objective"
                        ).length +
                      "%",
                  }}
                >
                  {column.headerName}
                </Text>
              ))}
          </View>
        </View>
        {props.data.map((item: any) => (
          <View
            style={
              isGreyBackground(item.objective)
                ? { ...styles.row, backgroundColor: "#dbdbdb" }
                : styles.row
            }
            key={item.objective}
          >
            <Text style={styles.descriptionRow}>
              {item.objective?.replace("≤", "<=")?.replace("≥", ">=")}
            </Text>
            <Text
              style={{
                textAlign: "center",
                paddingRight: 8,
                width: "20%",
                fontSize: "8px",
              }}
            >
              {item.valoration}%
            </Text>
            {props.columns
              .filter(
                (column: any) =>
                  !column.hide &&
                  column.field !== "valoration" &&
                  column.field !== "objective"
              )
              .map((column: any) => (
                <Text
                  style={{
                    textAlign: "center",
                    paddingRight: 8,
                    fontSize: "8px",
                    width:
                      40 /
                        props.columns.filter(
                          (column: any) =>
                            !column.hide &&
                            column.field !== "valoration" &&
                            column.field !== "objective"
                        ).length +
                      "%",
                  }}
                >
                  {item[column.field]?.replace("≤", "<=")?.replace("≥", ">=")}
                </Text>
              ))}
          </View>
        ))}
        <View
          style={{ display: "flex", flexDirection: "row", paddingTop: "5px" }}
        ></View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "5px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "5px",
              width: "35%",
            }}
          >
            <View
              style={{ border: "2px", borderColor: "black", width: "100%" }}
            >
              <Text
                style={{
                  width: "40%",
                  marginTop: "-12px",
                  marginLeft: "25%",
                  backgroundColor: "white",
                  color: "grey",
                  textAlign: "center",
                  fontSize: "14px",
                  fontFamily: "Heebo-bold",
                }}
              >
                Firma interesado/a
              </Text>
              <View
                style={{
                  display: "flex",
                  padding: "5px",
                  flexDirection: "row",
                }}
              >
                <Image
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "100%",
                  }}
                  src={
                    props.dataAll.employee_photo === "blank"
                      ? "/Assets/img/blank.png"
                      : "data:image/jpeg;base64," + props.dataAll.employee_photo
                  }
                ></Image>
                <View
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "10px",
                      color: "grey",
                    }}
                  >
                    {props.dataAll.employee_name}
                  </Text>
                  <Text
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "10px",
                      color: "grey",
                    }}
                  >
                    Fecha firma digital:{" "}
                    {moment(props.dataAll.updated_at)
                      .format("DD/MM/YYYY HH:mm")
                      .toString()}
                  </Text>{" "}
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  padding: "2px",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Image
                  style={{
                    height: "50px",
                  }}
                  src={props.dataAll.firma_employee}
                ></Image>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "5px",
              width: "35%",
              marginLeft: "20px",
            }}
          >
            <View
              style={{ border: "2px", borderColor: "black", width: "100%" }}
            >
              <Text
                style={{
                  width: "44%",
                  marginTop: "-12px",
                  marginLeft: "25%",
                  backgroundColor: "white",
                  color: "grey",
                  textAlign: "center",
                  fontSize: "14px",
                  fontFamily: "Heebo-bold",
                }}
              >
                {props.dataAll.firmado_orden
                  ? "Firma por orden"
                  : "Firma jefe directo"}
              </Text>
              <View
                style={{
                  display: "flex",
                  padding: "5px",
                  flexDirection: "row",
                }}
              >
                <Image
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "100%",
                  }}
                  src={
                    props.dataAll.manager_photo === "blank"
                      ? "/Assetsimg/blank.png"
                      : "data:image/jpeg;base64," + props.dataAll.manager_photo
                  }
                ></Image>

                <View
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "10px",
                      color: "grey",
                    }}
                  >
                    {props.dataAll.manager_name}
                  </Text>
                  <Text
                    style={{
                      textAlign: "left",
                      width: "100%",

                      fontSize: "10px",
                      color: "grey",
                    }}
                  >
                    Fecha firma digital:{" "}
                    {moment(props.dataAll.updated_at)
                      .format("DD/MM/YYYY HH:mm")
                      .toString()}
                  </Text>{" "}
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  padding: "2px",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Image
                  style={{
                    height: "50px",
                  }}
                  src={props.dataAll.firma_manager}
                ></Image>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              borderColor: "#dbdbdb",
              borderTop: "1px solid #dbdbdb",
              width: "70%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "grey",
                fontWeight: "black",
                fontFamily: "Heebo-bold",
                textAlign: "center",
                fontSize: "10px",
              }}
            >
              Validado Esther Duarte i Macarro - Directora RRCC.
            </Text>
            <Text
              style={{
                color: "grey",
                fontFamily: "Heebo",
                textAlign: "center",
                fontSize: "10px",
              }}
            >
              Fecha validación digital:
              {moment(props.dataAll.rrcc_validated)
                .format("DD/MM/YYYY HH:mm")
                .toString()}
            </Text>{" "}
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PDFObjective;

Font.register({
  family: "Heebo",
  src: "/Assets/Fonts/Heebo-VariableFont_wght.ttf",
});
Font.register({
  family: "Heebo-bold",
  src: "/Assets/Fonts/Heebo-Bold.ttf",
});
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#dbdbdb",
    borderBottomWidth: 1,
    alignItems: "center",
  },
  descriptionRow: {
    width: "50%",
    textAlign: "left",
    paddingLeft: 8,
    height: "auto",
    fontFamily: "Heebo",
    fontWeight: "bold",
    fontSize: "8px",
  },
  valueRow: {},

  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    borderWidth: 1,
    borderColor: "#092E3B",
  },
  page: {
    fontSize: 11,
    paddingTop: 10,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  container: {
    flexDirection: "row",
    borderBottomColor: "#092E3B",
    backgroundColor: "#092E3B",
    borderBottomWidth: 1,
    color: "white",
    alignItems: "center",
    height: 24,
    textAlign: "center",
    flexGrow: 1,
  },
  description: {
    width: "50%",
    borderRightColor: "white",
    borderRightWidth: 1,
    fontFamily: "Heebo-bold",
  },
  value: {
    width: "20%",
    borderRightColor: "white",
    borderRightWidth: 1,
    fontFamily: "Heebo-bold",
  },
});
const isGreyBackground = (value: any) => {
  if (
    value === "RESULTADOS CIA (M€)" ||
    value === "OBJETIVO" ||
    value === "EVALUACIÓN DEL DESEMPEÑO"
  ) {
    return true;
  }
  return false;
};
