import { Tooltip } from "@mui/material";
import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getCurrentPositionByID } from "../../../Service/DepartmentService/PositionService";
import { getEmployeeByID } from "../../../Service/EmployeeService/EmployeeConfig";
import { getMyObjectivesByYear } from "../../../Service/ObjectiveService/ObjectiveService";
import { sortObj, sortObjPersonal } from "../../../Utils/integerUtils";
import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import YearSelector from "../../Shared/YearSelector/YearSelector";
import "./MyObjetives.css";
import MyObjetivesTable from "./MyObjetivesTable/MyObjetivesTable";

function MyObjetives(props: any) {
  const [data, setData] = useState<any>([]);
  const [dataAll, setDataAll] = useState<any>([]);
  const [positionData, setPositionData] = useState<any>([]);
  const [error, setError] = useState<any>(false);
  const [isLoading, setIsLoading] = useState(true);
  const year = useSelector((state: any) => state.yearReducer.year);

  const [date, setDate] = useState<any>(
    moment(year?.toString() ?? "2022").toISOString()
  );

  const user = useSelector((state: any) => state.userReducer.user);
  const [columns, setColums] = useState<GridColDef[]>([
    { field: "id", headerName: "ID", width: 9, hide: true },
    { field: "header", headerName: "Header", width: 9, hide: true },
    {
      field: "objective",
      headerName: "Objetivo",
      minWidth: 350,
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <div
              style={{
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {params.value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "valoration",
      headerName: "Valoración % s/retirb. fija",
      minWidth: 200,
      flex: 1,
      editable: true,
      sortable: false,
      type: "number",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>
            {" "}
            {(params.row.valoration ? params.row.valoration + "%" : "").replace(
              ".",
              ","
            )}
          </div>
        );
      },
    },
  ]);

  useEffect(() => {
    getEmployeeByID(user.employee.id)
      .then((response: any) => {
        getCurrentPositionByID(response.data.id_position)
          .then((response: any) => {
            setPositionData(response.data);
          })
          .catch((error: any) => {
            toast.error("Ha habido un problema");
          });
      })
      .catch((error: any) => {
        toast.error("Ha habido un problema");
      });
  }, [date, user.employee.id]);

  useEffect(() => {
    getMyObjectivesByYear(user.employee.id, moment(date).format("YYYY"))
      .then((response) => {
        processData(response.data.all_objectives);
        setDataAll(response.data);
      })
      .catch((error) => {
        if (error?.response?.status !== 404) {
          toast.error("Ha habido un problema");
        }
        console.error(error);
        setError(true);
        setData([]);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, user.employee.id]);

  const processData = (objectives: any) => {
    let auxArray: any = [];
    let arrayCorporative: any = [];
    let arrayPersonal: any = [];
    let percentageCorporative = 0;
    let percentajePersonal = 0;
    let percentagePErformace_evaluation = 0;
    let auxColums: any = [];
    debugger;
    objectives.forEach((obj: any) => {
      if (obj.obj_type === "CORPORATIVE") {
        let corporative: any = [];
        corporative = obj.objectives.map(
          (objectCorporative: any, i: number) => {
            let auxTuple: any = {};
            objectCorporative.percentages.map((per: any) => {
              let value: string = per[0].toString();
              auxTuple[value] = per[1];
              if (i === 0) {
                auxColums.push({
                  renderCell: (params: any) => {
                    return (
                      <Tooltip title={params.value}>
                        <div
                          style={{
                            width: "100%",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            textAlign: "center",
                          }}
                        >
                          {params.value}
                        </div>
                      </Tooltip>
                    );
                  },
                  field: value,
                  headerName: value + "%",
                  minWidth: 110,
                  flex: 1,
                  editable: true,
                  sortable: false,
                  hide: per[1] == null ? true : false,
                });
              }
            });
            return { ...objectCorporative, ...auxTuple };
          }
        );
        arrayCorporative = corporative;
        percentageCorporative = obj.percentage;
      } else if (obj.obj_type === "PERSONAL") {
        let personal: any = [];
        personal = obj.objectives.map((objectCorporative: any, i: number) => {
          let auxTuple: any = {};
          objectCorporative.percentages.forEach((per: any) => {
            let value: string = per[0].toString();
            auxTuple[value] = per[1];
          });
          return { ...objectCorporative, ...auxTuple };
        });
        arrayPersonal = personal;
        percentajePersonal = obj.percentage;
      } else if (obj.obj_type === "PERFORMANCE EVALUATION") {
        percentagePErformace_evaluation = obj.percentage;
      }
    });

    auxArray.push({
      id: 0,
      objective: "RESULTADOS CIA (M€)",
      valoration: percentageCorporative,
      header: true,
    });
    auxArray = auxArray.concat(arrayCorporative.sort(sortObj));
    auxArray.push({
      id: 100,
      objective: "OBJETIVO",
      valoration: percentajePersonal,
      header: true,
    });
    auxArray = auxArray.concat(arrayPersonal.sort(sortObjPersonal));
    auxArray.push({
      id: 101,
      objective: "EVALUACIÓN DEL DESEMPEÑO",
      valoration: percentagePErformace_evaluation,
      header: true,
    });

    auxColums.sort((a: any, b: any) => {
      return parseInt(a.field) < parseInt(b.field) ? -1 : 1;
    });

    setData(auxArray);
    setColums(columns.concat(auxColums));
    setIsLoading(false);
  };

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      <div className="h-100 w-100 row">
        <h1 className="col-12 d-flex justify-content-center titleMyObjetives p-4">
          Mis objetivos
        </h1>
        <div className="col-12 d-flex justify-content-center">
          <YearSelector date={date} setDate={setDate}></YearSelector>
        </div>

        <div className="col-12 h-100 d-flex justify-content-center">
          {error ? (
            <div className="d-flex justify-content-center pt-4">
              <h1 style={{ color: "var(--aedas-red)" }}>
                No hay plantilla firmada para este año
              </h1>
            </div>
          ) : (
            <MyObjetivesTable
              columns={columns}
              data={data}
              rol={positionData.name}
              percentage={positionData.porcentaje}
              dataAll={dataAll}
            ></MyObjetivesTable>
          )}
        </div>
      </div>
    </>
  );
}

export default MyObjetives;
