import { IconButton, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderEditCellParams,
} from "@mui/x-data-grid";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getMyObjectivesByYear,
  getObjectivesByYear,
} from "../../../Service/ObjectiveService/ObjectiveService";
import { sortObj, sortObjPersonal } from "../../../Utils/integerUtils";
import AedasLoading from "../AedasLoading/AedasLoading";
import PDFObjective from "../PDFObjective/PDFObjective";
import "./ShowObjetivesContent.css";
import ReactLoading from "react-loading";
//@ts-ignore
import { saveAs } from "file-saver";
import { getExcellByUserAndYear } from "../../../Service/ExcelService/ExcelService";
import { SiMicrosoftexcel } from "react-icons/si";
import { GrDocumentPdf } from "react-icons/gr";
function ShowObjetivesContent(props: any) {
  const { selectedRow, isSigned } = props;
  const [isLoading, setisLoading] = useState(true);
  const [data, setData] = useState([]);
  const year = useSelector((state: any) => state.yearReducer.year);
  const [dataAll, setDataAll] = useState([]);

  const [columns, setColums] = useState<GridColDef[]>([
    { field: "id", headerName: "ID", width: 9, hide: true },
    { field: "header", headerName: "Header", width: 9, hide: true },
    {
      field: "objective",
      headerName: "Objetivo",
      minWidth: 350,
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <div
              style={{
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {params.value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "valoration",
      headerName: "Valoración % s/retirb. fija",
      minWidth: 200,
      flex: 1,
      editable: true,
      sortable: false,
      type: "number",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>
            {(params.row.valoration ? params.row.valoration + "%" : "").replace(
              ".",
              ","
            )}
          </div>
        );
      },
    },
  ]);
  useEffect(() => {
    if (isLoading) {
      if (isSigned) {
        getMyObjectivesByYear(selectedRow.id_employee, year)
          .then((response) => {
            processData(response.data.all_objectives);
            setDataAll(response.data);
          })
          .catch(() => {});
      } else {
        getObjectivesByYear(selectedRow.id_employee, year)
          .then((response) => {
            processData(response.data);
          })
          .catch((error) => console.log(error));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, selectedRow, year]);

  const processData = (objectives: any) => {
    let auxArray: any = [];
    let arrayCorporative: any = [];
    let arrayPersonal: any = [];
    let percentageCorporative = 0;
    let percentajePersonal = 0;
    let percentagePErformace_evaluation = 0;
    let auxColums: any = [];

    const getAuxColumns = (percentage: string, value: number) => {
      if (value) {
        auxColums.push({
          field: percentage,
          headerName: percentage + "%",
          minWidth: 110,
          flex: 1,
          editable: true,
          sortable: false,

          hide: value == null ? true : false,
          renderCell: (params: any) => {
            return (
              <Tooltip title={params.value}>
                <div
                  style={{
                    width: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textAlign: "center",
                  }}
                >
                  {params.value}
                </div>
              </Tooltip>
            );
          },
        });
      }
    };

    objectives.forEach((obj: any) => {
      if (obj.obj_type === "CORPORATIVE") {
        let corporative: any = [];
        corporative = obj.objectives.map(
          (objectCorporative: any, i: number) => {
            let auxTuple: any = {};
            objectCorporative.percentages.forEach((per: any) => {
              let percentage: string = per[0].toString();
              let value = per[1];
              auxTuple[percentage] = value;

              getAuxColumns(percentage, value);
            });
            return { ...objectCorporative, ...auxTuple };
          }
        );
        arrayCorporative = corporative;
        percentageCorporative = obj.percentage;
      } else if (obj.obj_type === "PERSONAL") {
        let personal: any = [];
        personal = obj.objectives.map((objectCorporative: any) => {
          let auxTuple: any = {};
          objectCorporative.percentages.forEach((per: any) => {
            let percentage: string = per[0].toString();
            let value = per[1];
            auxTuple[percentage] = value;

            getAuxColumns(percentage, value);
          });
          return { ...objectCorporative, ...auxTuple };
        });
        arrayPersonal = personal;
        percentajePersonal = obj.percentage;
      } else if (obj.obj_type === "PERFORMANCE EVALUATION") {
        percentagePErformace_evaluation = obj.percentage;
      }
    });
    auxArray.push({
      id: -3,
      objective: "RESULTADOS CIA (M€)",
      valoration: percentageCorporative,
      header: true,
    });

    auxArray = auxArray.concat(arrayCorporative.sort(sortObj));

    auxArray.push({
      id: -2,
      objective: "OBJETIVO",
      valoration: percentajePersonal,
      header: true,
    });

    auxArray = auxArray.concat(arrayPersonal.sort(sortObjPersonal));

    auxArray.push({
      id: -1,
      objective: "EVALUACIÓN DEL DESEMPEÑO",
      valoration: percentagePErformace_evaluation,
      header: true,
    });
    auxColums.sort((a: any, b: any) => {
      return parseInt(a.field) < parseInt(b.field) ? -1 : 1;
    });

    // const tableColumns: any = [];
    // auxColums.reduce((prev: any, current: any) => {
    //   if (prev.field == current.field) {
    //     if (prev.hide != current.hide) {
    //       prev.hide ? tableColumns.push(current) : tableColumns.push(prev);
    //     }
    //   }
    //   return current;
    // });

    auxColums = auxColums.filter(function (item: any, pos: any, self: any) {
      return self.findIndex((t: any) => t.field === item.field) === pos;
    });
    // auxColums =
    //   tableColumns.length > 0 && tableColumns.length < auxColums.length
    //     ? tableColumns
    //     : auxColums;

    setData(auxArray);
    setColums(columns.concat(auxColums));
    setisLoading(false);
  };
  const handleDownloadExcell = () => {
    getExcellByUserAndYear(selectedRow.id_employee, year).then((response) => {
      let blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(
        blob,
        "objetivos_" +
          year +
          "_" +
          (year + 1) +
          "_" +
          selectedRow.employee_display_name.replace(" ", "_") +
          ".xlsx"
      );
    });
  };
  if (isLoading || data.length <= 0 || (isSigned && dataAll.length <= 0)) {
    return <AedasLoading></AedasLoading>;
  }

  return (
    <>
      {/* <div className="personBoxShowObjetives d-flex justify-content-center pb-4 align-items-center">
          <img
            className="imgPersonShowObjetives"
            src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
          ></img>
          <div className="whiteBoxShowObjetives d-flex align-items-center">
          </div>
      </div> */}

      <h3 className="asignacionTitle">
        Objetivos de: {selectedRow.employee_display_name}
      </h3>
      <div
        style={{ display: "flex", justifyContent: "end", alignItems: "center" }}
      >
        {isSigned ? (
          <>
            <span>Descargar plantilla</span>
            <PDFDownloadLink
              document={
                <PDFObjective
                  year={year}
                  data={data}
                  columns={columns}
                  dataAll={dataAll}
                ></PDFObjective>
              }
              fileName={
                "objetivos_" +
                year +
                "_" +
                (year + 1) +
                "_" +
                selectedRow.employee_display_name +
                ".pdf"
              }
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <ReactLoading type="bubbles" color={"#092E3B"}></ReactLoading>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div></div>
                    <IconButton>
                      <GrDocumentPdf />
                    </IconButton>
                  </div>
                )
              }
            </PDFDownloadLink>
            <IconButton onClick={handleDownloadExcell}>
              <SiMicrosoftexcel color={"black"} />
            </IconButton>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="objetives_modal_Table">
        <DataGrid
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          density="compact"
          rows={data}
          columns={columns}
          disableSelectionOnClick
          hideFooter
          getRowClassName={(params) => {
            if (params.row.header) {
              return `greyBackground`;
            }
            return "";
          }}
        />
      </div>
    </>
  );
}

export default ShowObjetivesContent;
