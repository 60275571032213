import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import "./RrccValidation.css";
import ValidateModalContent from "./ValidateModalContent/ValidateModalContent";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getValidationsByRRHH } from "../../../Service/TemplateService/TemplateService";
import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import CustomModal from "../../Shared/ModalCustom/CustomModal";
import ShowObjetivesContent from "../../Shared/ShowObjetives/ShowObjetivesContent";
import RejectModalContent from "../../Shared/RejectModalContent/RejectModalContent";

function RrccValidation(props: any) {
  const [openModalShow, setOpenModalShow] = useState<boolean>(false);
  const [openModalValidate, setOpenModalValidate] = useState<boolean>(false);
  const [openModalReject, setOpenModalReject] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [data, setData] = useState<any>([]);
  const user = useSelector((state: any) => state.userReducer.user);

  const [selectedRow, setSelectedRow] = useState<any>(null);

  const handelValidate = (params: any) => {
    data.find((row: any) => row.id_template === params.row.id_template);
    setSelectedRow(
      data.find((row: any) => row.id_template === params.row.id_template)
    );
    setOpenModalValidate(true);
  };
  const handelReject = (params: any) => {
    setSelectedRow(
      data.find((row: any) => row.id_template === params.row.id_template)
    );
    setOpenModalReject(true);
  };

  const handelShowObjetives = (params: any) => {
    setSelectedRow(
      data.find((row: any) => row.id_template === params.row.id_template)
    );
    setOpenModalShow(true);
  };

  useEffect(() => {
    if (isLoading) {
      getValidationsByRRHH()
        .then((response: any) => {
          let aux = response.data.map((per: any, i: number) => {
            return {
              id: i,
              employee_display_name: per.employee_display_name,
              employee_image: per.url_img,
              id_employee: per.id_employee,
              manager_display_name: per.manager_display_name,
              id_template: per.id_template,
              status: per.status,
              url_img: per.url_img,
            };
          });

          setData(aux);
          setIsLoading(false);
        })
        .catch((error: any) => {
          if (error.request.status !== 404) {
            toast.error("ha habido un problema");
          }
          setData([]);
          setIsLoading(false);
        });
    }
  }, [isLoading]);

  const columns: GridColDef[] = [
    { field: "id_employee", headerName: "ID_employee", width: 90, hide: true },
    { field: "id", headerName: "ID", width: 90, hide: true },
    { field: "id_template", headerName: "id_template", width: 90, hide: true },
    {
      field: "employee_display_name",
      headerName: "Empleado",
      width: 300,
      flex: 1,
    },
    {
      field: "manager_display_name",
      headerName: "Manager",
      width: 150,
      flex: 1,
    },
    {
      field: "manager_display_name",
      headerName: "Manager",
      width: 150,
      flex: 1,
      hide: true,
    },
    {
      field: "status",
      headerName: "Estado",
      width: 150,
      flex: 1,
      renderCell: (params: GridValueGetterParams) => {
        const states = [
          { id: 1, name: "Pendiente", color: "#BBBCBC" },
          { id: 2, name: "Validando", color: "#041C2C" },
          { id: 3, name: "Pendiente de R.", color: "#88DBDF" },
          { id: 4, name: "Firmado", color: "#00B176" },
          { id: 5, name: "Rechazado", color: "#FF7E31" },
          { id: 6, name: "Rechazado", color: "#FF7E31" },
        ];
        const StateName: any = states.find((r) => r.id === params.value);
        return (
          <>
            <span
              style={{ backgroundColor: StateName.color }}
              className="chipTeamStatus"
            >
              {StateName ? StateName.name : ""}
            </span>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 400,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <>
            <Button
              onClick={() => {
                handelShowObjetives(params);
              }}
              color="primary"
              className="showObjetives_Button"
            >
              Ver objetivos
            </Button>
            <Button
              onClick={() => {
                handelValidate(params);
              }}
              color="primary"
              className="validateObjetives_Button "
            >
              Validar
            </Button>
            <Button
              onClick={() => {
                handelReject(params);
              }}
              color="primary"
              className="rejectObjetives_Button"
            >
              Rechazar
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (!openModalReject && openModalValidate) {
      setIsLoading(true);
    }
  }, [openModalValidate, openModalReject]);
  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <div className="px-4 h-100">
      <h1 className="titleNextYear">Validación RRCC</h1>
      <>
        <CustomModal
          size="lg"
          show={openModalValidate}
          onChange={() => {
            setOpenModalValidate(!openModalValidate);
          }}
          title="Confirmar Validación"
          body={
            <ValidateModalContent
              selectedRow={selectedRow}
              setOpen={setOpenModalValidate}
            ></ValidateModalContent>
          }
        ></CustomModal>
        <CustomModal
          size="xl"
          show={openModalShow}
          onChange={() => {
            setOpenModalShow(!openModalShow);
          }}
          title="Ver Objetivos"
          body={
            <ShowObjetivesContent
              selectedRow={selectedRow}
              setOpen={setOpenModalReject}
            ></ShowObjetivesContent>
          }
        ></CustomModal>

        <CustomModal
          size="lg"
          show={openModalReject}
          onChange={() => {
            setOpenModalReject(!openModalReject);
          }}
          title="Confirmar Rechazo"
          body={
            <RejectModalContent
              selectedRow={selectedRow}
              setOpen={setOpenModalReject}
              rrhh={true}
            ></RejectModalContent>
          }
        ></CustomModal>
        <div className="ValidateObjetives_table_container">
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[5, 10, 15]}
            disableSelectionOnClick
          />
        </div>
      </>
    </div>
  );
}

export default RrccValidation;
