import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getValidationsByManager = async (id_manager: number) => {
  return await axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/validation/all?id_manager=" +
      id_manager,
    {},
    getDefaultConfigHeader()
  );
};

export const getValidationsByRRHH = async () => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/validation/rrhh",
    getDefaultConfigHeader()
  );
};

export const getAllTemplate = async (year: number) => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/objectives/template/" + year,
    getDefaultConfigHeader()
  );
};

export const getUserNotificationsTemplate = async (employee_id: number) => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND +
      "/employee/home?id_employee=" +
      employee_id,
    getDefaultConfigHeader()
  );
};

export const sendToValidate = async (
  id_manager: number,
  id_employee: number,
  isCeo: boolean = false
) => {
  return await axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/validation?id_manager=" +
      id_manager +
      "&id_employee=" +
      id_employee +
      "&is_ceo=" +
      isCeo,

    {},
    getDefaultConfigHeader()
  );
};

export const validateTemplate = async (
  id_template: number,
  id_employee: number
) => {
  return await axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/validation/accept/?id_template=" +
      id_template +
      "&id_employee=" +
      id_employee,
    {},
    getDefaultConfigHeader()
  );
};
export const validateTemplateRRHH = async (
  id_template: number,
  id_employee: number
) => {
  return await axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/validation/rrhh/accept?id_template=" +
      id_template +
      "&id_rrhh=" +
      id_employee,
    {},
    getDefaultConfigHeader()
  );
};
export const validateTemplateDelegate = async (
  id_template: number,
  id_manager: number
) => {
  return await axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/validation/accept/delegate/?id_template=" +
      id_template +
      "&id_manager=" +
      id_manager,
    {},
    getDefaultConfigHeader()
  );
};
export const getSingsByManager = async (id_manager: number) => {
  return await axios.post(
    process.env.REACT_APP_API_BACKEND + "/signed/all?id_manager=" + id_manager,
    {},
    getDefaultConfigHeader()
  );
};
export const rejectSings = async (id_template: number, descripcion: string) => {
  return await axios.post(
    process.env.REACT_APP_API_BACKEND + "/signature/reject/",
    {
      id_template: id_template,
      description: descripcion,
    },
    getDefaultConfigHeader()
  );
};
export const rejectValidation = async (
  id_employee: number,
  id_template: number,
  descripcion: string
) => {
  return await axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/validation/reject/?id_employee=" +
      id_employee,
    {
      id_template: id_template,
      description: descripcion,
    },
    getDefaultConfigHeader()
  );
};

export const rejectValidationRRHH = async (
  id_rrhh: number,
  id_template: number,
  descripcion: string
) => {
  return await axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/validation/rrhh/reject?id_rrhh=" +
      id_rrhh,
    {
      id_template: id_template,
      description: descripcion,
    },
    getDefaultConfigHeader()
  );
};

export const getRejectDescripton = async (id_template: number) => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND +
      "/validation/rechazo?id_template=" +
      id_template,
    getDefaultConfigHeader()
  );
};

export const singTemplate = async (
  id_template: number,
  signManager: string,
  signEmployee: string,
  id_employee: number,
  id_manager: number
) => {
  return await axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/signature/accept/?id_template=" +
      id_template,
    {
      manager: {
        firma: signManager,
        id: id_manager,
      },
      employee: {
        firma: signEmployee,
        id: id_employee,
      },
    },
    getDefaultConfigHeader()
  );
};

export const getCeoSigns = async () => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/ceo/toSign",
    getDefaultConfigHeader()
  );
};

export const getBusinessManagerValidations = async () => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/negocio/validations",
    getDefaultConfigHeader()
  );
};

export const singTemplateByOrder = async (
  id_template: number,
  signManager: string,
  signEmployee: string,
  id_employee: number,
  id_manager: number
) => {
  return await axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/signature/byOrder/?id_template=" +
      id_template,
    {
      manager: {
        firma: signManager,
        id: id_manager,
      },
      employee: {
        firma: signEmployee,
        id: id_employee,
      },
    },
    getDefaultConfigHeader()
  );
};
