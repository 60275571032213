import Button from "@mui/material/Button";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getExcellStatusByYear } from "../../../Service/ExcelService/ExcelService";
import { getAllTemplate } from "../../../Service/TemplateService/TemplateService";
import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import CustomModal from "../../Shared/ModalCustom/CustomModal";
import ShowNotifications from "../../Shared/ShowNotifications/ShowNotifications";
import ShowObjetivesContent from "../../Shared/ShowObjetives/ShowObjetivesContent";
import ExcelAllDownload from "./ExcelAllDownload";
import "./TemplateStatus.css";

function TemplateStatus(props: any) {
  const [openModalShow, setOpenModalShow] = useState<boolean>(false);
  const [openModalShowNotifications, setOpenModalShowNotifications] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const year = useSelector((state: any) => state.yearReducer.year);

  const [data, setData] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedRowNotifications, setSelectedRowNotifications] =
    useState<any>(null);
  const handelShowObjetives = (params: any) => {
    setSelectedRow(
      data.find((row: any) => row.id_template === params.row.id_template)
    );
  };
  const handelShowNotifications = (params: any) => {
    setSelectedRowNotifications(
      data.find((row: any) => row.id_template === params.row.id_template)
    );
  };
  useEffect(() => {
    if (selectedRow) {
      setOpenModalShow(true);
    }
  }, [selectedRow]);

  useEffect(() => {
    if (selectedRowNotifications) {
      setOpenModalShowNotifications(true);
    }
  }, [selectedRowNotifications]);

  useEffect(() => {
    if (!openModalShow) {
      setSelectedRow(null);
    }
  }, [openModalShow]);

  useEffect(() => {
    if (!selectedRowNotifications) {
      setSelectedRowNotifications(null);
    }
  }, [selectedRowNotifications]);

  useEffect(() => {
    if (isLoading) {
      getAllTemplate(year)
        .then((response: any) => {
          let aux = response.data.map((per: any, i: number) => {
            return {
              id: i,
              employee_display_name: per.employee_display_name,
              id_employee: per.id_employee,
              manager_display_name: per.manager_display_name,
              id_template: per.id_template,
              status: per.status,
              notifications: per.notifications,
            };
          });

          setData(aux);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [isLoading, year]);

  const columns: GridColDef[] = [
    { field: "id_employee", headerName: "ID_employee", width: 90, hide: true },
    { field: "id", headerName: "ID", width: 90, hide: true },
    { field: "id_template", headerName: "Template", width: 90, hide: true },
    {
      field: "employee_display_name",
      headerName: "Empleado",
      width: 300,
      flex: 1,
    },
    {
      field: "manager_display_name",
      headerName: "Manager",
      width: 150,
      flex: 1,
    },
    {
      field: "notifications",
      headerName: "Acciones en espera",
      width: 150,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Estado",
      width: 150,
      flex: 1,
      renderCell: (params: GridValueGetterParams) => {
        const states = [
          { id: 1, name: "Pendiente", color: "#BBBCBC" },
          { id: 2, name: "Validando", color: "#041C2C" },
          { id: 3, name: "Pendiente de R.", color: "#88DBDF" },
          { id: 4, name: "Firmado", color: "#00B176" },
          { id: 5, name: "Rechazado", color: "#FF7E31" },
          { id: 6, name: "Rechazado", color: "#FF7E31" },
        ];
        const StateName: any = states.find((r) => r.id === params.value);
        return (
          <>
            <span
              style={{ backgroundColor: StateName.color }}
              className="chipTeamStatus"
            >
              {StateName ? StateName.name : ""}
            </span>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 400,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <>
            <Button
              onClick={() => {
                handelShowObjetives(params);
              }}
              color="primary"
              className="showObjetives_Button"
            >
              Ver objetivos
            </Button>
            {params.row.status === 4 ? <></> : <></>}
            {params.row.notifications !== 0 ? (
              <Button
                onClick={() => {
                  handelShowNotifications(params);
                }}
                color="primary"
                className="showObjetives_Button"
              >
                Ver acciones pendientes
              </Button>
            ) : (
              <div style={{ width: "170px" }}></div>
            )}
          </>
        );
      },
    },
  ];

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      <CustomModal
        size="xl"
        show={openModalShow}
        onChange={() => {
          setOpenModalShow(!openModalShow);
        }}
        title="Ver Objetivos"
        body={
          selectedRow && selectedRow.status === 4 ? (
            <ShowObjetivesContent
              isSigned={true}
              selectedRow={selectedRow}
            ></ShowObjetivesContent>
          ) : (
            <ShowObjetivesContent
              selectedRow={selectedRow}
            ></ShowObjetivesContent>
          )
        }
      ></CustomModal>
      <CustomModal
        size="xl"
        show={openModalShowNotifications}
        onChange={() => {
          setOpenModalShowNotifications(!openModalShowNotifications);
        }}
        title="Acciones pendientes"
        body={
          <ShowNotifications
            selectedRow={selectedRowNotifications}
          ></ShowNotifications>
        }
      ></CustomModal>
      <div className="px-4 h-100">
        <h1 className="titleTeams">Estados</h1>
        <ExcelAllDownload year={year}></ExcelAllDownload>
        <div
          style={{ height: "80%" }}
          className="ValidateObjetives_table_container"
        >
          <DataGrid rows={data} columns={columns} disableSelectionOnClick />
        </div>{" "}
      </div>
    </>
  );
}

export default TemplateStatus;
